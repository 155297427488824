




















/* tslint:disable:no-console */
import { Component, Vue, Watch } from "vue-property-decorator";
import IndexTemplate from "@/components/Index-template.vue";
import updateTdk from "@/utils/tdk";

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "Home",
  components: { IndexTemplate }
})
export default class Home extends Vue {
  // 初始数据可以直接声明为实例的属性
  private isMounted: boolean = false;
  private lists: any[] = [];
  private keyWordArr: any[] = [];
  private keyWord: string = "";
  private keyId = 0;
  private tdk(self: any) {
    return {
      title: ""
    };
  }
  private serverPrefetch() {
    this.$store.commit("clearPageData"); // 清空全部，重要
    return this.fetchData();
  }
  get indexList(): any[] {
    return this.$store.state.pageDatas.indexData || [];
  }
  private fetchData() {
    const self: any = this;
    // 获取首页数据
    self.$doMotion(self, "LOADING_SWITCH", true);
    const cookies = this.$ssrContext && this.$ssrContext.cookies;
    return this.$store
      .dispatch("fetchIndexData", { params: { start: 0, max: 50 } })
      .then(res => {
        const result = res.value;
        if (result) {
          // 添加数据来源
          const val = (result.blocks || []).map((it: any) => {
            it.data_source = "index";
            return it;
          });
          self.$store.commit("setIndexData", val);
        }
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || "服务器错误，请刷新！");
      })
      .finally(() => {
        self.$doMotion(self, "LOADING_SWITCH", false);
        updateTdk(self);
      });
  }
  private fetchHotKeyword() {
    const self: any = this;
    this.$store
      .dispatch("fetchHotKeyWord", {
        params: {}
      })
      .then((result: any) => {
        this.keyWordArr = result.value.data;
        this.keyWord = result.value.data[0].title;
      })
      .catch((err: any) => {
        self.$toast.show(err.message || "服务器错误，请刷新！");
      });
  }
  @Watch("keyWord")
  private changeKeyWord() {
    if (this.keyWordArr.length === 0) {
      return;
    }
    const len = this.keyWordArr.length;
    setTimeout(() => {
      if (this.keyId < len - 1) {
        this.keyId = this.keyId + 1;
        this.keyWord = this.keyWordArr[this.keyId].title;
      } else {
        this.keyId = 0;
        this.keyWord = this.keyWordArr[this.keyId].title;
      }
    }, 2000);
  }
  private mounted(): void {
    const self: any = this;
    self.isMounted = true;
    if (!self.indexList.length) {
      // 是否有值
      self.fetchData();
    }
    self.fetchHotKeyword();
    updateTdk(self);
  }
  private destroyed() {
    this.$store.commit("setIndexData", []); // 修改store
  }
  private toSearchDetail(): void {
    const word = this.keyWord;
    this.$router.push({ name: "searchDetail", params: { word } });
  }
}
